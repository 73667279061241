import { useState } from "react";
import {
  Box,
  TransparentButton,
  Text, LegacyText,
  SecondaryButton,
  TertiaryButton,
} from "flicket-ui";
import { Icon, ContentModal } from "~components";
import {
  ExtendedFile,
  LandingPage,
  VenueAddressFragment,
  VenueInfoFragment,
} from "~graphql/sdk";
import BaseHeader from "./common.BaseHeader";
import { getEventVenueLocation } from "~lib/helpers/getEventVenueLocation";
import { useOrganization } from "~hooks";
import { formatEventDateRange } from "~lib/helpers/formatDate";

type EventHeaderProps = {
  goBack: () => void;
  title: string;
  startDate: string;
  endDate?: string;
  type: "event" | "membership";
  image?: ExtendedFile;
  coordinates?: string;
  accessibility?: string;
  description?: string;
  supportInfo?: string;
  venue: VenueInfoFragment & VenueAddressFragment;
  location?: string;
};

export default function EventHeader({
  title,
  startDate,
  endDate,
  location: maybeLocation,
  venue,
  coordinates,
  goBack,
  type,
  accessibility,
  description,
  image,
  supportInfo,
}: EventHeaderProps) {
  const { organization, hasFeature, t } = useOrganization();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [isOpenSupport, setIsOpenSupport] = useState(false);

  const location = maybeLocation ?? getEventVenueLocation(venue);

  let backTxt = `Back to ${
    type === "membership"
      ? t("membership_name_plural").toLowerCase()
      : "schedule"
  }`;

  if (organization.landingPage === LandingPage.SingleEvent) {
    backTxt = `Back to tickets`;
  }

  const formattedDateRange = formatEventDateRange(startDate, endDate, {
    format: "basicLong",
    showStartTime: !hasFeature("hideEventTime"),
    timeZone: venue?.timezone,
    locale: venue?.locale,
  });

  const subTitle = (
    <>
      {formattedDateRange}
      &nbsp;
      <Box as="span" d={{ _: "none", md: "block" }}>
        - {location}
      </Box>
    </>
  );

  return (
    <>
      <BaseHeader
        title={title}
        subTitle={subTitle}
        backTxt={backTxt}
        onClickBack={goBack}
        mobileMenuItems={
          <>
            {description && (
              <TransparentButton
                fontSize={3}
                fontWeight="regular"
                lineHeight="normal"
                color="N700"
                whiteSpace="nowrap"
                onClick={() => setIsOpenInfo(true)}
              >
                <Icon icon="info" fontSize={6} color="P300" mr="6/4" />
                Event info
              </TransparentButton>
            )}
            {accessibility && (
              <TransparentButton
                fontSize={3}
                fontWeight="regular"
                lineHeight="normal"
                color="N700"
                whiteSpace="nowrap"
                onClick={() => setIsOpen(true)}
              >
                <Icon icon="accessibility_purple" fontSize={6} mr="6/4" />
                Accessibility info
              </TransparentButton>
            )}
            {supportInfo && (
              <TransparentButton
                fontSize={3}
                fontWeight="regular"
                lineHeight="normal"
                color="N700"
                whiteSpace="nowrap"
                onClick={() => setIsOpenSupport(true)}
              >
                <Icon icon="support" fontSize={6} mr="6/4" />
                Support info
              </TransparentButton>
            )}
          </>
        }
        desktopMenuItems={
          <>
            {accessibility && (
              <TertiaryButton
                px="6/4"
                py={"9px" as any}
                onClick={() => setIsOpen(true)}
              >
                <Icon icon="accessibility" fontSize={5} mr={1} />
                <LegacyText
                  color="N600"
                  fontSize={2}
                  fontWeight="extraBold"
                  lineHeight="normal"
                >
                  Accessibility
                </LegacyText>
              </TertiaryButton>
            )}
            {description && (
              <SecondaryButton
                ml={2}
                px="6/4"
                py={"9px" as any}
                onClick={() => setIsOpenInfo(true)}
              >
                <Icon icon="info" fontSize={5} color="P300" mr={1} />
                <LegacyText
                  color="P300"
                  fontSize={2}
                  fontWeight="extraBold"
                  lineHeight="normal"
                >
                  {organization.landingPage === LandingPage.SingleEvent
                    ? "Ticket"
                    : type === "event"
                    ? "Event"
                    : t("membership_name")}{" "}
                  Info
                </LegacyText>
              </SecondaryButton>
            )}
            {supportInfo && (
              <SecondaryButton
                px="6/4"
                py={"9px" as any}
                ml={2}
                onClick={() => setIsOpenSupport(true)}
              >
                <Icon icon="support" fontSize={5} mr={1} />
                <LegacyText
                  color="P300"
                  fontSize={2}
                  fontWeight="extraBold"
                  lineHeight="normal"
                >
                  Support
                </LegacyText>
              </SecondaryButton>
            )}
          </>
        }
      >
        <>
          <ContentModal
            isOpen={isOpen}
            setIsOpen={() => setIsOpen(false)}
            title="Accessibility"
            richText={accessibility}
          />
          {description && (
            <ContentModal
              isOpen={isOpenInfo}
              setIsOpen={() => setIsOpenInfo(false)}
              title={title}
              image={image}
              richText={description}
              subtitle={location}
              location={location}
              coordinates={coordinates}
              date={formattedDateRange}
            />
          )}
          {supportInfo && (
            <ContentModal
              isOpen={isOpenSupport}
              setIsOpen={() => setIsOpenSupport(false)}
              title="Support information"
              richText={supportInfo}
            />
          )}
        </>
      </BaseHeader>
    </>
  );
}
